/* eslint-disable react/jsx-fragments */
import React from 'react';
import { object, shape } from 'prop-types';
import { Script } from 'nordic/script';

import Section from '../../../commons/section';
import withTracker from '../../../commons/with-tracker';

function AdvertisingAdnVideoBanner({ content }) {
  const [videoContent] = content?.fast_loading?.items;
  const { event_tracking, content: { actions, images, texts, video, wrapper_content } } = videoContent;

  const propsVideoBanner = {
    primary_title: texts?.primary_title,
    wrapper_banner_primary_title: wrapper_content?.texts?.primary_title,
    wrapper_banner_secondary_title: wrapper_content?.texts?.secondary_title,
    badge_text: wrapper_content?.badge?.text,
    badge_background_color: wrapper_content?.badge?.background,
    badge_icon: wrapper_content?.badge?.icon_id,
    finished_overlay_button_text: video?.finished_overlay?.texts?.button,
    video_title: video?.texts?.title,
    cta: texts?.cta,
    destination_url: actions?.content_link?.link,
    finished_overlay_destination_url: video?.finished_overlay?.actions?.content_link?.link,
    video_destination_url: video?.actions?.content_link?.link,
    video_dash_url: video?.url?.dash,
    video_hls_url: video?.url?.hls,
    audio_lang: JSON.stringify(video?.config?.audio_languages),
    thumbnail: video?.images?.thumbnail,
    picture: images?.picture,
    primary_logo: wrapper_content?.images?.primary_logo,
    print_url: JSON.stringify(event_tracking?.print),
    click_url: JSON.stringify(event_tracking?.click),
    view_url: JSON.stringify(event_tracking?.view),
    finished_overlay_click_url: JSON.stringify(video?.finished_overlay?.event_tracking?.click),
    video_click_url: JSON.stringify(video?.event_tracking?.click),
  };

  return (
    <Section type="adn" className="ad_adn_video_banner">
      <React.Fragment>
        <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/video-simple-banner.1.6.4.js" />
        <video-simple-banner {...propsVideoBanner} />
      </React.Fragment>
    </Section>
  );
}

AdvertisingAdnVideoBanner.propTypes = {
  content: shape({
    fast_loading: object.isRequired,
  }).isRequired,
};

export default withTracker(AdvertisingAdnVideoBanner);
